export const APP_BACKEND_URL: string = 'backend.terralogs.com.br';

export const URL_BASE_API: string = 'backend.terralogs.com.br';

export const URL_BUCKET: string = 'content.terralogs.com.br';

export const MAP_ICON: string = 'assets/icon/marker.svg'

export const MAP_ICON_SELECTED: string = 'assets/icon/marker-selected.svg'

export const TILE_LAYER: string = 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';

export const TILE_ID: string = 'mapbox/satellite-streets-v11';

export const TILE_ACCESS_TOKEN: string = 'pk.eyJ1IjoiZ3VzdGF2b3ZpZWlyYTk5MiIsImEiOiJja2piZmN3MGcwazFwMnVxcWh0YWVubjdoIn0.rny57jknqMG45YR2nlrojA';

export const USER_TERRALOGS: string = 'terralogsdocumentos@gmail.com';

export const PASSWORD_TERRALOGS: string = 'Z3LlOp4S5__2022';

export const FIELDS_TERRALOGS: string = 'city,farm_name,area,register_date,geometry_centroid,geometry_smoothed,prices,owner_name,secundary_owner_name,climate_type,biome_type,relief_type,distance,nearest_urban_city,registration_number,overlap,ground_type,origin';

export const bucketDocumentos: string = 'terralogs-apis-arquivoteca-assets-tl-dsv';

